import React, { useState, useEffect } from "react"
import "./app.scss"
import LazyImage from "./LazyImage"
import LogoLow from "../images/logo_low.png"
import LogoHigh from "../images/logo_high-01.svg"
import Slider from "react-slick"
import { Drawer } from "antd"
import Cart from "./Cart"
import { Link } from "gatsby"

function Navbar(props) {
  const [visible, setVisible] = useState(true)
  const [current, setCurrent] = useState("pre-order")
  const [width, setWidth] = useState(1400)
  const [count, setCount] = useState(0)
  const [price, setPrice] = useState(0)
  const [shipCharge, setShipCharge] = useState(5)
  const [isCombo, setIsCombo] = useState(false)
  const [showPromo, setShowPromo] = useState(false)
  const [promo, setPromo] = useState("")
  const [promoError, setPromoError] = useState("")
  const [discount, setDiscount] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (currentScrollY >= 800 && currentScrollY < 3070) {
        setCurrent("coming-soon")
      } else if (currentScrollY > 3070) {
        setCurrent("about-us")
      } else {
        setCurrent("pre-order")
      }
    }

    setWidth(window.innerWidth)

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [current])

  useEffect(() => {
    getCount()
    setVisible(!visible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.added])

  const getCount = () => {
    setIsCombo(false)
    setPromo("")
    setShowPromo(false)
    const cart = localStorage.getItem("cupid_cart")
    const discount = localStorage.getItem("discount")

    if (discount !== null) {
      setShowPromo(true)
      setDiscount(+discount)
    } else {
      setDiscount(0)
    }

    if (cart === null) {
      setCount(0)
    } else {
      let cartData = JSON.parse(cart)
      let price = 0

      cartData.forEach(product => {
        const unitPrice = +product.quantity * product.price

        price += unitPrice

        if (product.category === "COMBO") {
          setIsCombo(true)
          setShipCharge(0)
        } else if (
          product.category === "SCRAPBOOK" ||
          product.category === "BOTTLE" ||
          product.category === "TEDDY"
        ) {
          setShipCharge(10)
        } else {
          setShipCharge(5)
        }
      })

      setPrice(price)

      setCount(cartData.length)
    }
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    arrows: false,
    fade: false,
    autoplaySpeed: 3500,
  }

  return (
    <div className=" fixed-top">
      <Slider {...settings}>
        <div className="text-center bg-accent p-0 m-0">
          <h5 className="text-white pt-2 pb-1 mob-font">
            15% off in all products
          </h5>
        </div>
        <div className="text-center bg-accent p-0 m-0">
          <h5 className="text-white pt-2 pb-1 mob-font">
            Get free shipping on Valentines day Special package{" "}
            <Link
              to="/product/FWvPqyWawGBZ2O5B6RD2"
              className="alert-link text-white"
            >
              View offer
            </Link>
          </h5>
        </div>
      </Slider>
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container text-center">
          <Link className="navbar-brand" to="/">
            <LazyImage lowRes={LogoLow} highRes={LogoHigh} width="200px" />
          </Link>
          <span className="navbar-text">
            <a
              href={props.location.pathname === "/" ? "#pre-order" : "/"}
              className={
                current === "pre-order" ? "nav-link active" : "nav-link"
              }
            >
              Pre-order
            </a>
            <a
              href={props.location.pathname === "/" ? "#products" : "/"}
              className={
                current === "coming-soon" ? "nav-link active" : "nav-link"
              }
            >
              Products
            </a>
            <a
              href={props.location.pathname === "/" ? "#about-us" : "/"}
              className={
                current === "about-us" ? "nav-link active" : "nav-link"
              }
            >
              About Us
            </a>
            <button
              onClick={() => setVisible(true)}
              className="nav-link nav-cart"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-bag mb-2 "
                viewBox="0 0 21 18"
              >
                <path
                  d="M8,1a2.5,2.5,0,0,1,2.5,2.5V4h-5V3.5A2.5,2.5,0,0,1,8,1Zm3.5,3V3.5a3.5,3.5,0,0,0-7,0V4H1V14a2,2,0,0,0,2,2H13a2,2,0,0,0,2-2V4ZM2,5H14v9a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1Z"
                  transform="translate(-1)"
                />
                <circle cx="12" cy="12" r="6" fill="#9e1e8c" />
                <text
                  x="56%"
                  y="80%"
                  textAnchor="middle"
                  fontSize="8"
                  fill="#fff"
                  fontFamily="Lato"
                >
                  {count}
                </text>
              </svg>
            </button>
          </span>
        </div>
      </nav>

      <Drawer
        title={"Shopping Cart (" + count + ")"}
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
        key="cart"
        width={width < 1200 ? 300 : 500}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="products">
            <Cart show={visible} setCount={getCount} />
          </div>
          <div>
            {+price > 0 ? (
              <div className="row py-2 align-items-center">
                <h6 className="col-6">Subtotal: </h6>
                <div className="col-6">
                  <h6 style={{ textAlign: "right" }} className="text-dark">
                    ${price.toFixed(2)}
                  </h6>
                </div>
                {!showPromo ? (
                  <>
                    <h6 className="col-12 pt-4">Apply a promo code</h6>
                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Promo Code"
                        value={promo}
                        onChange={e => setPromo(e.target.value)}
                      />
                    </div>
                    <button
                      className="col-4 btn btn-primary"
                      onClick={() => {
                        setPromoError("")
                        if (promo.toUpperCase() === "SPECIAL10") {
                          setShowPromo(true)
                          localStorage.setItem("discount", "10")
                          getCount()
                        } else {
                          setPromoError("Invalid promo code")
                        }
                      }}
                    >
                      Apply
                    </button>
                    <h6 className="col-12 text-danger">{promoError}</h6>
                    <div className="col-12 py-3" />
                  </>
                ) : (
                  <>
                    <h6 className="col-6">${+discount} Discount: </h6>
                    <div className="col-6">
                      <h6 style={{ textAlign: "right" }} className="text-dark">
                        -${+discount.toFixed(2)}
                      </h6>
                    </div>

                    <h6 className="col-12 mb-3" style={{ textAlign: "right" }}>
                      <button
                        className="no-style-btn text-accent"
                        onClick={() => {
                          setShowPromo(false)
                          localStorage.removeItem("discount")
                          getCount()
                        }}
                      >
                        Remove promo code
                      </button>
                    </h6>
                  </>
                )}
                <h6 className="col-6">Shipping Charge: </h6>
                <div className="col-6">
                  <h6 style={{ textAlign: "right" }} className="text-dark">
                    {isCombo ? "$0.00" : `$${shipCharge.toFixed(2)}`}
                  </h6>
                </div>
                <h6 className="col-6">8.87% Tax: </h6>
                <div className="col-6">
                  <h6 style={{ textAlign: "right" }} className="text-dark">
                    $
                    {isCombo
                      ? ((+price - +discount + 0.0) * 0.0887).toFixed(2)
                      : ((+price - +discount + +shipCharge) * 0.0887).toFixed(
                          2
                        )}
                  </h6>
                </div>
                <hr></hr>
                <h6 className="col-6">Total Price: </h6>
                <div className="col-6">
                  <h4 style={{ textAlign: "right" }} className="text-accent">
                    $
                    {isCombo
                      ? ((+price - +discount + 0.0) * 1.0887).toFixed(2)
                      : ((+price - +discount + +shipCharge) * 1.0887).toFixed(
                          2
                        )}
                  </h4>
                </div>
              </div>
            ) : (
              ""
            )}
            {+price > 0 ? (
              <Link to="/checkout" className="btn btn-primary col-md-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-credit-card-2-back-fill"
                  viewBox="0 0 18 18"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z" />
                </svg>
                &nbsp;&nbsp;Proceed to checkout
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </Drawer>

      <button onClick={() => setVisible(true)} className="floating-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          className="bi bi-bag mb-2 "
          viewBox="-2 0 21 18"
        >
          <path
            d="M8,1a2.5,2.5,0,0,1,2.5,2.5V4h-5V3.5A2.5,2.5,0,0,1,8,1Zm3.5,3V3.5a3.5,3.5,0,0,0-7,0V4H1V14a2,2,0,0,0,2,2H13a2,2,0,0,0,2-2V4ZM2,5H14v9a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1Z"
            transform="translate(-1)"
          />
          <circle cx="12" cy="12" r="6" fill="#ffffff" />
          <text
            x="55%"
            y="82%"
            textAnchor="middle"
            fontSize="8"
            fill="#9e1e8c"
            fontFamily="Lato"
          >
            {count}
          </text>
        </svg>
      </button>
    </div>
  )
}

export default Navbar
