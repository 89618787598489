import React, { useState, useEffect } from "react"
import LazyImage from "./LazyImage"
import Low from "../images/low_gradient.png"

export default function Cart(props) {
  const { show } = props

  const [data, setData] = useState([])

  useEffect(() => {
    fetchCartData()
  }, [show])

  const fetchCartData = () => {
    const cart = localStorage.getItem("cupid_cart")

    if (cart === null) {
      setData([])
    } else {
      let cartData = JSON.parse(cart)
      setData(cartData)
    }
  }

  const removeFromCart = index => {
    let cartData = data
    cartData.splice(index, 1)
    console.log(cartData)

    localStorage.setItem("cupid_cart", JSON.stringify(cartData))

    fetchCartData()
    props.setCount()
  }

  return (
    <div>
      {data.map((el, index) => (
        <div key={index} className="row align-items-center cart-item">
          <div className="col-4">
            <LazyImage
              lowRes={Low}
              highRes={el.image}
              alt="..."
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                padding: "2%",
              }}
            />
          </div>
          <div className="col-6">
            <h5>{el.name}</h5>
            <p>Quantity: {el.quantity}</p>
            <b>Price: ${+el.quantity * +el.price}</b>
          </div>
          <div className="col-2">
            <button
              type="button"
              onClick={() => removeFromCart(index)}
              class="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>
      ))}
    </div>
  )
}
